import React from "react";
import { graphql } from "gatsby";

// JS logic
import { getLocale } from "~utils";

// Components
import Layout from "~layout";
import TermsPage from "~components/terms/termsPage";

const CategoryEntry = ({ data, location }) => {
  const {
    markdownRemark: {
      html: mdContent,
      frontmatter: { locale: localeKey, metadata, date, link },
    },
  } = data;

  const locale = getLocale(localeKey);
  const page = {
    metadata,
    date,
    link,
  };

  return (
    <Layout helmetOnly={true} location={location} locale={locale} pageMetadata={metadata}>
      <TermsPage locale={locale} page={page} mdContent={mdContent} />
    </Layout>
  );
};

export default CategoryEntry;

export const pageQuery = graphql`
  query CategoryEntryByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        locale
        metadata {
          title
          description
        }
        date(formatString: "MM / DD / YYYY")
        link {
          text
          target
        }
      }
    }
  }
`;
