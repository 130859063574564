import React from "react";
import { Link } from "gatsby";

import { localizedUrl } from "~utils";

import { RenderHTML } from "~wrappers/renderContent";

const TermsPage = ({ locale, page: { metadata, date, link }, mdContent }) => {
  if (!locale || !metadata || !date || !link || !mdContent) {
    return "";
  }

  return (
    <div className="stf-terms">
      <div className="stf-container">
        <div className="columns is-centered">
          <div className="column is-6-desktop">
            <h1>{metadata.title}</h1>
            <p>{date}</p>
            <Link
              className="stf-terms__return-link"
              to={localizedUrl(locale, link.target)}
            >
              {link.text}
            </Link>
            <div className="stf-terms__page-content">
              <RenderHTML content={mdContent} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TermsPage;
